import * as core from 'core';
import * as app from 'app';
import $ from 'jquery';

import init from 'init.coffee';

window.ls = Object.assign({},core,app);
window.jQuery = $; // TODO: remove

document.addEventListener("DOMContentLoaded", () => {

    const currentPage = window.location.pathname;
    let index = 0, startIndex = 1;
    while ((index = currentPage.indexOf('/', startIndex)) > -1) {
        import('./actions/'+currentPage.slice(1,index)+'.js').then((action) => action.init()).catch((e)=>{}/*console.log('skipping '+currentPage.slice(1,index))*/);
        startIndex = index + 1;
    }

    init();
});
