import xhr from 'xhr';
import {merge} from 'lodash';
import {error, notice} from 'core/messages';

import {gettext} from 'core/lang';


function encodeParams(payload) {
    return Object
        .keys(payload)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(payload[k])}`)
        .join('&');
}

const postRaw = function(url,data = {}) {
    const options = {};
    if(data instanceof FormData) {
        data.set('security_ls_key',window.LIVESTREET_SECURITY_KEY);
        options['body'] = data;
    }
    else {
        options['body'] = encodeParams(merge(data, {security_ls_key: window.LIVESTREET_SECURITY_KEY})); // todo заменить merge на Object.assign?
        options['headers'] = {'Content-Type': 'application/x-www-form-urlencoded'};
    }
    /* todo перейти на fetch в будущем... пока невозможно из-за отсутствия AbortController в FF < 57.
    const controller = new AbortController();
    let signal = controller.signal;
    data = Object.assign({signal}, data);
    const promise = fetch(input, data);
    promise.controller = controller;
    return promise;*/
    let xhr_object;
    const promise = new Promise((resolve, reject) => {
        xhr_object = xhr.post(url, options, (err, res, body) =>
            err ? reject(err) : resolve(res)
        );
        /* todo нужно ли reject promise в случае вызова xhr.abort()?
        const onabort_origin = xhr_object.onabort;
        xhr_object.onabort = function() {
            onabort_origin();
            reject(null);
        };*/
    });
    promise.xhr = xhr_object;
    return promise;
};
const postProcessed = function(promise) {
    return promise
        .catch((err) => {
            error(gettext('network_error'), gettext('data_not_send'));
            return Promise.reject(err);
        })
        .then((result) => {
            if (!result) {
                error(gettext('network_error'), gettext('try_later'));
                return Promise.reject(result);
            }
            if (result.body === 'Hacking attempt!') {
                error(null, result.body);
                return Promise.reject(result);
            }

            try {
                const jsonBody = JSON.parse(result.body);
                if (jsonBody.bStateError) {
                    error(jsonBody.sMsgTitle, jsonBody.sMsg);
                    return Promise.reject(result);
                }
                return Promise.resolve(jsonBody);
            } catch {
                // ignore json parse error
            }

            return Promise.resolve(result);
        });
}

function post(route, data = {}) {
    return postProcessed(postRaw(route,data));
}

function AjaxLoader() {
    let form, elementWithLoading = 'button[type="submit"]', url = undefined, formData = undefined, loading = false;

    /** @param {HTMLFormElement} value */
    this.setForm = (value) => { form = value; return this; }
    /** @param {HTMLElement|string} value */
    this.setElementWithLoading = (value) => { elementWithLoading = value; return this; }
    /** @param {string} value */
    this.setUrl = (value) => { url = value; return this; }
    /** @param {Object|FormData} value */
    this.setData = (value) => { formData = value; return this; }

    let xhr = null, ewl = null;
    this.post = async () => {
        if(loading) return false;
        if(!form && formData === undefined) throw new Error('No HTML form or direct data is specified.');
        ewl = form ? form.querySelector(':scope '+elementWithLoading)
            : (elementWithLoading instanceof HTMLElement ? elementWithLoading : document.querySelector(elementWithLoading));
        if(ewl !== null) ewl.classList.add('loading');
        loading = true;
        if(formData === undefined)
            formData = new FormData(form);
        if(url === undefined)
            url = form.action || '';
        try {
            let p = postRaw(url,formData);
            xhr = p.xhr || null;
            return await postProcessed(p);
        }
        finally {
            loading = false;
            if(ewl !== null) ewl.classList.remove('loading');
        }
    };
    this.isActive = () => { return loading; }
    this.abort = () => {
        if(loading && xhr && 'abort' in xhr) {
            xhr.abort();
            loading = false;
            if(ewl !== null) ewl.classList.remove('loading');
        }
    };
}

export default {post, encodeParams, AjaxLoader};
